<template>
  <div class="chat pb-1 pb-md-4">
    <template v-if="isMobileAndChatNotSelect">
      <h1 class="text-h5 font-weight-bold">{{ $t('chat.chats') }}</h1>

      <chat-list v-if="!isNoChats" class="mt-4">
        <chat-search mobile :user-search.sync="userSearch" @select-user="selectUser" />
        <chat-list-item
          v-for="chat in chatsWithFoundedUsers"
          :key="chat.user.id"
          :name="chat.user.name"
          :unread-count="chat.unreadCount"
          :units="chat.user.units"
          :is-active="selectedChatId === chat.id"
          @click="selectChat(chat)"
        />
      </chat-list>

      <base-missing-data v-else class="chat__chats-missing-message mt-3">
        <template #icon>
          <v-icon class="chat__missing-icon" size="34">
            mdi-email
          </v-icon>
        </template>

        <template #message>
          {{ $t('chat.employee_chats_missing_message') }}
        </template>
      </base-missing-data>
    </template>

    <v-container v-else-if="canChooseChatRoom" class="chat__container pa-0" fluid>
      <v-row class="chat__row" no-gutters>
        <v-col v-if="!media.isMobile" class="chat__sidebar" xl="3" md="4">
          <chat-list class="chat__list">
            <chat-search :user-search.sync="userSearch" @select-user="selectUser" />
            <chat-list-item
              v-for="chat in chatsWithFoundedUsers"
              :key="chat.user.id"
              :name="chat.user.name"
              :unread-count="chat.unreadCount"
              :units="chat.user.units"
              :is-active="selectedChatId === chat.id"
              @click="selectChat(chat)"
            />
          </chat-list>
        </v-col>

        <v-col class="chat__window-wrapper d-flex flex-column" xl="9" md="8" sm="12">
          <v-alert v-model="isError" class="chat__error" type="error" dismissible>
            {{ windowError }}
          </v-alert>
          <chat-units-header v-if="!media.isMobile" :client-id="currentClientId" class="chat__employee-header" />
          <chat-window
            ref="chatWindow"
            class="chat__window"
            :messages="messagesWithOnlineStatus"
            :files="files"
            :is-no-chats="isNoChats"
            :is-chat-selected="!!currentChatId"
            :chat-id="currentChatId"
            @fetch-messages="fetchMessages"
            @send="sendMessage"
            @upload-file="uploadFile"
            @remove-file="removeFile"
            @read="readMessage"
          />
        </v-col>
      </v-row>
    </v-container>

    <template v-else>
      <div class="chat__container chat__container--client">
        <v-alert v-model="isError" class="chat__error" type="error" dismissible>
          {{ windowError }}
        </v-alert>

        <chat-window
          ref="chatWindow"
          class="chat__window"
          :messages="messagesWithOnlineStatus"
          :files="files"
          :is-chat-selected="!!currentChatId"
          :chat-id="currentChatId"
          @fetch-messages="fetchMessages"
          @send="sendMessage"
          @upload-file="uploadFile"
          @remove-file="removeFile"
          @read="readMessage"
        />
      </div>
    </template>

    <v-snackbar v-model="lostConnectionSnackbarIsShow" elevation="0" right color="secondary" timeout="-1">
      <span class="primary--text font-weight-medium text-body-2">{{ $t('chat.lost_connection') }}</span>
    </v-snackbar>
  </div>
</template>

<script>
// Components
import ChatWindow from '@/components/Chat/Window.vue';
import ChatList from '@/components/Chat/List.vue';
import ChatListItem from '@/components/Chat/ListItem.vue';
import ChatUnitsHeader from '@/components/Chat/UnitsHeader.vue';
import BaseMissingData from '@/components/BaseMissingData.vue';
import ChatSearch from '@/components/Chat/Search.vue';

// Helpers
import { mapState, mapMutations } from 'vuex';

// Constants
import {
  NEW_MESSAGE,
  CLIENTS_GET_ROOMS,
  EMPLOYEES_GET_ROOMS,
  EMPLOYEES_GET_ROOM,
  EMPLOYEES_ROOM,
} from '@/constants/chatEvents';
import { SET_SELECTED_CHAT } from '@/store/modules/chat/mutations/types';
import { CHATS_LIST, CHAT_VIEWING, SENDING_MESSAGE, SENDING_MESSAGE_WITH_FILE } from '@/constants/analyticsActions';
import { MANAGEMENT_COMPANY_EMPLOYEE } from '@/constants/roles';

// Services
import chatService from '@/services/chat';
import mediaService from '@/services/media';
import { fetchChatClients } from '@/services/select';
import { loadStorageItem, saveStorageItem } from '@/services/storage';
import analyticsService from '@/services/analytics';

// Utils
// eslint-disable-next-line no-unused-vars
import { debounce } from '@/utils/delay';
import { getUuid } from '@/utils/generators';

// Http
import client from '@/http/client';
import { flushPromises } from '@/utils/scheduler';

const MAX_FILE_SIZE = 1.5e7;
const CHAT_CACHE_KEY = 'chatCache';
const UPDATE_INTERVAL = 7000;

export default {
  name: 'Chat',

  inject: ['media'],

  components: { ChatWindow, ChatList, ChatListItem, ChatUnitsHeader, BaseMissingData, ChatSearch },

  props: {
    selectedChatId: { type: Number, default: -1 },
  },

  data: () => ({
    messages: [],
    offset: 0,
    limit: 10,
    messagesIsLoading: false,
    messagesLoadingComplete: false,
    lostConnectionSnackbarIsShow: false,
    isLoadingUnits: false,
    windowError: '',
    files: [],
    userSearch: '',
    foundedUsers: [],
    tempRoom: {},
    roomCache: {},
    isError: false,
    employeeParticipants: [],
  }),

  computed: {
    ...mapState({
      chats: state => state.chat.employeeRoomList,
      clientRoomId: state => state.chat.clientRoomId,
      userRole: state => state.user.role,
      participants: state => state.chat.participants,
      onLine: state => state.common.onLine,
      wsIsConnected: state => state.common.wsIsConnected,
    }),

    isEmployee() {
      return this.userRole === MANAGEMENT_COMPANY_EMPLOYEE;
    },

    chatsWithFoundedUsers() {
      if (this.isSearch) {
        return this.foundedUsers.map(user => {
          return {
            user: { name: `${user.firstName} ${user.lastName}`, id: user.id, units: user.units },
            unreadCount: 0,
          };
        });
      }

      return this.chats;
    },

    isSearch() {
      return this.userSearch !== '';
    },

    participantMap() {
      if (this.isEmployee) {
        return this.employeeParticipants.reduce((map, participant) => {
          return { ...map, [participant.userId]: participant };
        }, {});
      }

      let { participants } = this;

      if (this.canChooseChatRoom) {
        participants = this.currentChat?.participants;
      }

      return participants?.reduce((map, participant) => {
        return { ...map, [participant.userId]: participant };
      }, {});
    },

    messagesWithOnlineStatus() {
      return this.messages.map(message => {
        return { ...message, isOnline: this.participantMap?.[message.authorId]?.isOnline };
      });
    },

    canChooseChatRoom() {
      return this.isEmployee;
    },

    isMobileAndChatNotSelect() {
      return this.media.isMobile && !this.selectedChatId && this.canChooseChatRoom;
    },

    currentChatId() {
      return this.clientRoomId || this.selectedChatId;
    },

    currentChat() {
      const foundChat = this.chats.find(chat => chat.id === this.selectedChatId);

      if (foundChat) {
        return foundChat;
      }

      if (this.tempRoom && Object.keys(this.tempRoom).length > 0) {
        return this.tempRoom;
      }

      return this.roomCache[this.selectedChatId];
    },

    currentClientId() {
      return this.currentChat?.user?.id;
    },

    isNoChats() {
      return !this.chats.length;
    },
  },

  watch: {
    currentChat(chat) {
      if (chat && Object.keys(chat).length > 0 && this.selectedChatId) {
        this.roomCache[this.selectedChatId] = chat;
        saveStorageItem(CHAT_CACHE_KEY, JSON.stringify(this.roomCache));
      }

      this.setSelectedChat(chat);
    },

    userSearch(newSearch) {
      if (newSearch === '') {
        this.foundedUsers = [];
        return;
      }

      this.debounceSearchClients();
    },

    onLine(newStatus) {
      if (!newStatus) {
        this.lostConnectionSnackbarIsShow = true;
        return;
      }

      this.lostConnectionSnackbarIsShow = false;
    },

    wsIsConnected(status) {
      if (status && this.currentChatId) {
        chatService.switchRoom(this.selectedChatId || this.clientRoomId);
      }
    },

    selectedChatId: {
      async handler(chatId) {
        if (!chatId) return;
        this.offset = 0;
        this.messages = [];
        this.messagesLoadingComplete = false;

        if (this.wsIsConnected && this.currentChatId) {
          chatService.switchRoom(this.selectedChatId);
        }

        await this.fetchMessages();

        this.$nextTick(() => {
          this.$refs.chatWindow.scrollToBottom();
        });
      },
      immediate: true,
    },

    clientRoomId: {
      async handler(chatId) {
        if (!chatId) return;

        await this.fetchMessages();

        if (this.wsIsConnected && this.currentChatId) {
          chatService.switchRoom(chatId);
        }

        this.$nextTick(() => {
          this.$refs?.chatWindow?.scrollToBottom();
        });
      },
      immediate: true,
    },

    messages(newMessages) {
      newMessages.filter(message => !message.isRead).forEach(message => this.readMessage(message));
    },
  },

  mounted() {
    if (this.isEmployee) {
      this.$options.getRoomInterval = setInterval(() => {
        if (this.currentChatId) {
          chatService.socket.send(EMPLOYEES_GET_ROOM);
        }
      }, UPDATE_INTERVAL);

      chatService.socket.on(EMPLOYEES_ROOM, this.setParticipants);
    }

    analyticsService.track(CHATS_LIST);

    chatService.socket.addListener('error', error => {
      if (error?.detail?.toLowerCase() === 'not in a room') {
        if (this.currentChatId) {
          chatService.switchRoom(this.currentChatId);
          if (this.isEmployee) {
            chatService.socket.send(EMPLOYEES_GET_ROOM);
          }

          chatService.resendLastMessages();
        }
      }
    });

    if (this.wsIsConnected && this.currentChatId) {
      chatService.switchRoom(this.currentChatId);
    }

    if (this.selectedChatId !== -1) {
      this.fetchMessages();
    }

    chatService.socket.on(NEW_MESSAGE, this.newMessage);
  },

  beforeMount() {
    this.roomCache = JSON.parse(loadStorageItem(CHAT_CACHE_KEY)) || {};
  },

  beforeDestroy() {
    chatService.socket.removeListener(NEW_MESSAGE, this.newMessage);
    chatService.socket.removeListener(EMPLOYEES_ROOM, this.setParticipants);
    clearInterval(this.$options.getRoomInterval);
    chatService.leaveChat();
  },

  methods: {
    ...mapMutations({
      setSelectedChat: SET_SELECTED_CHAT,
    }),

    setParticipants({ room }) {
      this.employeeParticipants = room.participants;
    },

    searchClients() {
      fetchChatClients({ search: this.userSearch, isChatStarted: true }).then(({ results }) => {
        this.foundedUsers = results;
      });
    },

    // eslint-disable-next-line func-names
    debounceSearchClients: debounce(function() {
      this.searchClients();
    }),

    selectUser(userData) {
      chatService.createRoom(userData.userId).then(room => {
        this.selectChat(room);
        this.tempRoom = room;
      });
    },

    uploadFile(files) {
      // eslint-disable-next-line no-restricted-syntax
      for (const file of files) {
        const id = getUuid();

        const newFile = { id, isLoading: true, isError: false, name: file.name, progress: 0, backgroundId: null };

        if (file.size > MAX_FILE_SIZE) {
          newFile.isError = true;
          this.files.push(newFile);
          this.windowError = this.$t('error.large_file_size_15_mb');
          this.isError = true;
          return;
        }

        this.files.push(newFile);

        const cancelSource = client.getCancelToken();

        mediaService
          .uploadFile(file, this.currentChatId, {
            cancelToken: cancelSource.token,
            onUploadProgress: progressEvent => {
              const originFile = this.files.find(currentFile => currentFile.id === id);
              originFile.cancel = cancelSource.cancel;

              if (originFile) {
                originFile.progress = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 75), 10);
              }
            },
          })
          .then(response => {
            const originFile = this.files.find(currentFile => currentFile.id === id);
            originFile.progress = 100;

            if (originFile) {
              originFile.backgroundId = response.id;
            }
          })
          .catch(() => {
            const originFile = this.files.find(currentFile => currentFile.id === id);

            if (originFile) {
              originFile.isError = true;
            }
          });
      }
    },

    removeFile(fileId) {
      const fileIndex = this.files.findIndex(file => file.id === fileId);

      if (this.files[fileIndex].isError) {
        this.isError = false;
      }

      if (fileIndex !== -1) {
        if (this.files[fileIndex].progress !== 100 && !this.files[fileIndex].isError) {
          this.files[fileIndex]?.cancel();
        }

        this.files.splice(fileIndex, 1);
      }
    },

    async selectChat(chat) {
      if (this.isSearch) {
        const room = await chatService.createRoom(chat.user.id);

        this.selectChat(room);
        this.userSearch = '';
      }

      const query = { ...this.$route.query, id: chat.id };

      analyticsService.track(CHAT_VIEWING, analyticsService.createChatViewingPayload(chat.user.name));
      this.$router.push({ query });
    },

    localFetchUnits() {
      const clientId = this.chats.find(({ id }) => id === this.selectedChatId)?.user?.id;

      if (clientId) {
        this.fetchUnitsByClientId(clientId);
      }
    },

    fetchUnitsByClientId(clientId) {
      this.isLoadingUnits = true;

      this.fetchUnits(clientId).finally(() => {
        this.isLoadingUnits = false;
      });
    },

    async fetchMessages() {
      if (this.$options.messageFetchCancelToken) {
        this.$options.messageFetchCancelToken.cancel();
        await flushPromises();
      }

      if (!this.currentChatId) return Promise.resolve();

      if (this.messagesIsLoading || this.messagesLoadingComplete) return Promise.resolve();
      this.messagesIsLoading = true;

      const cancelSource = client.getCancelToken();
      this.$options.messageFetchCancelToken = cancelSource;

      return chatService
        .getMessages(
          { roomId: this.currentChatId, limit: this.limit, offset: this.offset, role: this.userRole },
          {
            cancelToken: cancelSource.token,
          }
        )
        .then(messages => {
          if (messages.length === 0) {
            this.messagesLoadingComplete = true;
            return;
          }
          this.messages = [...messages, ...this.messages];
          this.offset += messages.length;
        })
        .finally(() => {
          this.messagesIsLoading = false;
          this.$options.messageFetchCancelToken = null;
        });
    },

    sendMessage(data) {
      if (!data && !this.files.length) return;
      chatService.sendMessage(
        data,
        this.files.map(file => file.backgroundId)
      );

      if (this.files.length) {
        analyticsService.track(
          SENDING_MESSAGE_WITH_FILE,
          analyticsService.createSendingMessageWithFilePayload(this.currentChat?.user?.name, this.files.length)
        );
      } else {
        analyticsService.track(
          SENDING_MESSAGE,
          analyticsService.createSendingMessagePayload(this.currentChat?.user?.name)
        );
      }

      this.files = [];
    },

    newMessage(messageData) {
      this.offset += 1;

      this.messages = [...this.messages, chatService.formatMessage(messageData.message)];

      if (this.isEmployee) {
        chatService.socket.send(EMPLOYEES_GET_ROOMS);
      } else {
        chatService.socket.send(CLIENTS_GET_ROOMS);
      }

      this.$nextTick(() => {
        this.$refs.chatWindow.scrollToBottom();
      });
    },

    readMessage(message) {
      chatService.readMessage(message.id);
    },
  },

  reconnectTimerInstance: null,
  messageFetchCancelToken: null,
};
</script>

<style lang="scss">
// 200px - Высота шапки сайта + округление, чтобы подвал немного выпирал
$bottom-padding: 200px;

.chat {
  height: calc(100vh - #{$bottom-padding});

  @media (max-width: map.get($--screens, 'sm')) {
    height: 100%;
  }

  &__window-wrapper {
    position: relative;

    @media (max-width: map.get($--screens, 'sm')) {
      position: initial;
    }
  }

  &__error {
    top: 0;
    width: 100%;
    z-index: 8;
    position: absolute !important;

    @media (max-width: map.get($--screens, 'sm')) {
      z-index: 1000;
      top: -56px;
      width: 100%;
      left: 0;
      font-size: 16px !important;
    }
  }

  &__sidebar {
    border-right: 1px solid $--blue-color-50 !important;
    background-color: $--blue-color-60;

    overflow-y: auto;
    position: relative;
  }

  &__list {
    position: absolute;
    width: 100%;
  }

  &__container {
    height: 100%;

    &--client {
      position: relative;

      .chat__error {
        @media (max-width: map.get($--screens, 'sm')) {
          width: calc(100% + 16px);
          left: -8px;
        }
      }
    }
  }

  &__container,
  &__row {
    min-height: calc(100vh - #{$bottom-padding});

    @media (max-width: map.get($--screens, 'sm')) {
      height: 100%;
    }
  }

  &__missing-icon {
    opacity: 0.4;
    color: $--black-color-0;
  }

  &__chats-missing-message {
    min-height: 200px;
    height: 100%;
  }

  .v-snack__wrapper {
    min-width: 304px !important;
  }
}
</style>
