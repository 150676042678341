<template>
  <v-btn
    text
    class="download-button justify-start py-2"
    height="auto"
    :class="color"
    @click="download"
    v-on="inheritedListeners"
  >
    <v-progress-circular
      v-if="isLoading"
      :rotate="-90"
      size="40"
      :width="3"
      :value="downloadProgress"
      :color="dark ? '#E6EBFF' : '#C7D6FF'"
    >
      <v-icon size="24" :color="iconColor">mdi-download</v-icon>
    </v-progress-circular>

    <v-progress-circular v-else-if="isError" :rotate="-90" size="40" :width="3" value="100" color="error">
      <v-icon size="24" :color="iconColor">mdi-reload</v-icon>
    </v-progress-circular>

    <v-avatar v-else size="40" :color="iconColor">
      <v-icon :color="dark ? 'primary' : 'white'" size="18">{{ currentIcon }}</v-icon>
    </v-avatar>

    <span class="download-button__link-text text-left text-body-2">
      <div class="ml-2 d-flex flex-column align-start">
        <span :class="{ 'download-button__dark-file-name': !dark }">
          {{ trimFileName(name) }}
        </span>
        <span :class="{ 'text--secondary': !dark }"> {{ isLoading ? `${downloadProgress}%` : size }} </span>
      </div>
    </span>
  </v-btn>
</template>

<script>
// Services
import mediaService from '@/services/media';

// Utils
import { download } from '@/utils/common';
import { middleTruncate } from '@/utils/formatters';
import { getInheritedListeners } from '@/utils/components';

// http
import { getBaseUrlWithoutApi } from '@/http/getBaseURL';
import client from '@/http/client';

// Constants
const ICON_COLORS = {
  WHITE: 'white',
  DARK: '#002FFF',
};

export default {
  name: 'DownloadButton',

  props: {
    dark: { type: Boolean, default: false },
    href: { type: String, default: '#' },
    icon: { type: String, default: 'mdi-file-outline' },
    name: { type: String, required: true },
    size: { type: [String, Number], default: '' },
  },

  data() {
    return {
      isDownloaded: false,
      isLoading: false,
      isError: false,
      downloadProgress: 0,
      terminated: false,
    };
  },

  computed: {
    color() {
      return this.dark ? 'white--text' : 'dark--text';
    },

    currentIcon() {
      if (!this.isDownloaded) return 'mdi-download';

      return this.icon;
    },

    iconColor() {
      if (this.dark) {
        return ICON_COLORS.WHITE;
      }

      return ICON_COLORS.DARK;
    },

    inheritedListeners: getInheritedListeners(['click']),
  },

  methods: {
    async download() {
      if (this.isLoading) {
        this.terminated = true;
        this.$options.cancelSource.cancel();
        return;
      }

      this.isLoading = true;
      this.isDownloaded = false;
      this.terminated = false;
      this.downloadProgress = 0;
      this.$options.cancelSource = client.getCancelToken();

      try {
        const fullUrl = this.checkUrlType(this.href) === 'absolute' ? this.href : getBaseUrlWithoutApi() + this.href;

        const blob = await mediaService.getPrivateMediaBlob(fullUrl, {
          cancelToken: this.$options.cancelSource.token,
          cache: true,
          onDownloadProgress: ({ loaded, total }) => {
            this.downloadProgress = parseInt(Math.round((loaded / total) * 100), 10);
          },
        });

        download(this.name, blob);
        this.isDownloaded = true;
      } catch {
        if (!this.terminated) {
          this.isError = true;
        }
      } finally {
        this.isLoading = false;
      }
    },

    checkUrlType(url) {
      try {
        const parsedUrl = new URL(url);
        return parsedUrl.protocol ? 'absolute' : 'relative';
      } catch (error) {
        return url.startsWith('/') ? 'relative' : 'invalid';
      }
    },

    trimFileName(name) {
      return middleTruncate(name);
    },
  },

  cancelSource: null,
};
</script>

<style lang="scss">
.download-button {
  white-space: normal;
  word-break: break-all;

  &__link-text {
    opacity: 0.9;
    vertical-align: bottom;
  }

  &__dark-file-name {
    color: $--blue-color-0;
  }

  .v-progress-circular__overlay {
    transition: none;
  }
}
</style>
